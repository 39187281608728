<template>
  <b-modal
    id="image-preview"
    ref="fimage-preview"
    title="Preview"
    hide-footer
    @hidden="resetModal"
    style="min-width: 800px"
  >
    <b-overlay :show="loading">
      <div style="width: 100%; height: 600px; max-height: 600px">
        <img
          :src="preview"
          alt=""
          style="width: 100%; height: 100%; object-fit: cover"
        />
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    image: {
      type: String,
    },
  },

  computed: {
    preview() {
      return this.image;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    resetModal() {
      this.$emit('image-preview', null);
    },
  },
};
</script>

<style lang="scss">
#food-modal {
  .modal-dialog {
    max-width: 80%;
  }
}
.aterisk {
  color: red;
}
</style>
